.newsLetter{
    display: flex;
    flex-direction: column;
    gap:2rem;
    width:50%;
    padding: 3rem 1rem;
}

.heading h1{
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 500;
    line-height: 1.2;
    color: black;
    text-align: center;
}

.subscribe p{
    color:#bcb8b7;
    font-size:1.2rem;
    text-align: center;
}

.form{
    display: flex;
    flex-direction: column;
    gap:1rem
}

.email{
    color: #464446;
    background-color: #d6d0ce;
    border: none;
    padding: 0.8rem 1rem;
    width: 100%;
    font-weight: bold;
    border-bottom: 4px solid #716b67;
}
.signup{
    background-color: rgba(0,0,0,0.9);
    color:#fff;
    border:none;
    width:100%;
    padding: 0.8rem;
    font-weight: bolder;
}

@media screen and (max-width:850px){
    .newsLetter{
        width: 70%;
    }
}

@media screen and (max-width:620px){
    .newsLetter{
        width: 85%;
        padding: 3rem 0;
    }
}

@media screen and (max-width:470px){
    .newsLetter{
        width: 90%;
    }
    .subscribe p{
        font-size: 1rem;
    }
}
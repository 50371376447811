.readyToStart,.formPart{
    background-image: url("../../../public/images/abstract-shapes-12.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 -8rem;
}
.formPart{
    z-index: -5;
    background-image: url("../../../public/images/shape-3.svg");
    margin-top: 0;
    display: flex;
    justify-content: center;
}

.disable{
    cursor: not-allowed !important;
}

.messageResponse p{
    text-align: center;
    padding: 0.5rem;
    color:#377dff;
    font-weight: bold;
}


.formPartWrapper{
    width: 70%;
    margin:0 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 6rem;
    padding-right: 6rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.readyToStartContainer{
    padding-top: 7rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 4rem;
    margin:0 3rem;

}

.workingTogetherBtn{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:1rem
}

.workingTogetherBtn p{
    font-size: 80%;
    font-weight: 400;
    opacity: .4;
    color:#4e5862;
}

.workingTogetherBtn .btn{
    background-color: #377dff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #f7faff;    
    min-width: 13rem;
    font-weight: bold;
    line-height: 1.6;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.workingTogetherBtn .btn:hover{
    color: #677788;
    box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
    transform: translateY(-2px);
}

.readyToStartContainer h1{
    font-size: 2.5rem;
    padding: 0 13rem;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}
.readyToStartContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:1rem
}

.formPartWrapper input,.formPartWrapper textarea{
    width: 100%;
    border: 1px solid #377dff;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: #677788;
    /* background-color: #377dff1c; */
}
.inputField{
    position: relative;
    z-index: 1;
}
.formPartWrapper label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #377dff;
    transition: all 250ms ease-in-out;

}
input:focus ~ label,
input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
textarea:focus ~ label,
textarea:valid ~ label{
    transform: translateY(-35px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

textarea{
    resize: none;
}

.newsLetter{
    display: flex;
    background-color: #f5f5f5;
    justify-content: center;
    margin: 4.5rem -8rem;
    padding: 3rem 0;
}


@media screen and (max-width:1115px){
    .readyToStartContainer{
        padding-top: 5rem;
        padding-left: 0;
        padding-right: 0;
    }
    .readyToStartContainer h1{
        padding: 1rem;
    }
}

@media screen and (max-width:990px){
    .newsLetter,.readyToStart,.formPart{
        margin: 0 -3rem;
    }
}

@media screen and (max-width:870px){
    .readyToStartContainer h1{
        font-size: 1.5rem;
        padding: 1rem 0;
    }
}

@media screen and (max-width:750px){
    .formPartWrapper{
       padding: 3rem 0;
    }   
}

@media screen and (max-width:530px){
    .newsLetter,.readyToStart,.formPart{
        margin: 0 -1rem;
    }

    .formPartWrapper{
        width: 85%;
        margin: 0 1rem;
    }
    .readyToStartContainer{
        margin: 1rem;
    }
    .readyToStartContainer h1{
        font-size: 1rem;
    }
}


.hero{
    position: relative;
    overflow: hidden;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    gap:2rem;
    margin:0 -8rem ;
    padding: 8rem;
}
      
.hero::before {
    border-radius: 100%;
    position: absolute;
    background: #377dff!important;
    right: -700px;
    left: -700px;
    top: -700px;
    z-index: -111;
    content: '';
    bottom: 0;
}
.message h1{
    font-size: calc(1.575rem + 3.9vw);
    text-shadow: 5px 6px 16px rgb(27, 1, 59);
    font-weight: 600;
    line-height: 1.2;
    color:#fff;
    text-align: center;
}
.animation h1{
    text-align: center;
}
.animation h1 span{
    color: #fff;
    padding: 0.7rem;
    letter-spacing: 1.5px;
}
.animation h1 span:nth-child(2){
    padding: 0;
}
.getInTouchBtn{
    text-align: center;
}
.getInTouchBtn button{
    display: inline-block;
    font-weight: 600;
    color: #677788;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #fff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0.3125rem;    
    box-shadow: 0 4px 11px rgba(247,250,255,.35);
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.getInTouchBtn button:hover{
    transform: translateY(-2px);
}

.salesforce{
    margin: 6rem 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(270px,1fr));
    justify-content: space-around;
    grid-gap:3rem
}

.different{
    margin-top: 5rem;
    padding: 3rem 0;
    display: flex;

}
.different .leftPart{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap:1rem
}

.leftPart h1{
    font-size: calc(1.26609rem + .19312vw);
}

.leftPart p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: left;
}

.different .rightPart{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightPart .img{
    width: 70%;
}

.rightPart .img img{
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
}
.startNow button{
    background-color: #377dff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #f7faff;    
    font-weight: bold;
    line-height: 1.6;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.startNow button:hover{
    box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
    transform: translateY(-2px);
    color: #677788;
}

.letsConnect{
    display: flex;
    flex-direction: column;
    gap:2.5rem;
    justify-content: center;
    align-items: center;
}

.content{
    width: 100%;
    border-radius: 12px;
    padding: 4rem;
    background-color: #21325b;    
    box-shadow: 0 0 50px rgba(55,125,255,.4);
    display: flex;
    align-items: center;
    
}

.content .left,.content .right{
    flex: 1 1;
}

.formPart{
    width: 100%;
    z-index: -5;
    background-image: url("../../../public/images/shape-3.svg");
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:2rem 1rem;
    box-shadow: 1px 1px 1px 1px #4e5862;
    justify-content: center;
    border-radius: 0.75rem;
    box-shadow: 0 10px 40px 30px rgba(140,152,164,.175)
}

.formPart h3{
    padding: 1rem 2rem;
}

.formPartWrapper{
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.workingTogetherBtn{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:1rem
}

.workingTogetherBtn p{
    font-size: 80%;
    font-weight: 400;
    opacity: .4;
    color:#4e5862;
}

.workingTogetherBtn .btn{
    background-color: #377dff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #f7faff;    
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.6;
    letter-spacing: 1.5px;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.formPartWrapper input{
    width: 100%;
    border: 1px solid #377dff;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: #677788;
    /* background-color: #377dff1c; */
}

.inputField{
    position: relative;
    z-index: 1;
}
.formPartWrapper label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #377dff;
    transition: all 250ms ease-in-out;

}
input:focus ~ label,
input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
.workingTogetherBtn .btn:hover{
    color: #677788;
    box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
    transform: translateY(-2px);
}
.privacy p{
    font-size: .875rem;
    padding:0 0.5rem;
    line-height: 1.5;
}

.privacy p span{
    color: #377dff;
    font-weight: bold;
    cursor: pointer;

}
.left{
    display: flex;
    flex-direction: column;
    gap:1rem
}
.left h2{
    padding: 1rem;
    padding-right: 2rem;
    color: #fff;
}

.left p{
    padding: 0.5rem 1rem;
    padding-right: 3rem;
    padding-left: 2rem;
    color: #fff;
    position: relative;
}

.left p::before{
    content: '';
    position: absolute;
    height: 100%;
    background-color: #fff;
    width: 4px;
    border-radius: 20px;
    top:0;
    left: 15px;
}

.disable{
    cursor: not-allowed !important;
}

.messageResponse p{
    text-align: center;
    padding: 0.5rem;
    color:#377dff;
    font-weight: bold;
}


@media screen and (max-width:990px){
    .hero{
        margin: 0 -3rem;
    }

    .content{
        flex-direction: column;
        gap:2rem
    }
}

@media screen and (max-width:880px){
    .different{
        flex-direction: column-reverse;
        gap:3rem;
    }

    .salesforce{
        grid-template-columns: repeat(1, minmax(270px,1fr));
    }
    .hero{
        padding: 8rem 3rem;
    }
}
@media screen and (max-width:650px){
    .left p,.left h2{
        padding-right: 0;
    }
    .content{
        padding: 4rem 1rem;
    }
}

@media screen and (max-width:570px){
    .hero{
        padding: 8rem 1rem;
    }
    .animation h1 span{
        padding: 0rem;
        font-size: 1.4rem;
    }
}
@media screen and (max-width:530px){
    .hero{
        margin: 0 -1rem;
    }
}
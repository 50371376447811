.cardAndImageContainer{
    flex: 1 1;
    border: 0.0625rem solid rgba(231,234,243,.7);
    border-radius: 0.75rem;
    padding: 2rem;
    position: relative;
}

.cardAndImageWrapper{
    word-wrap: break-word;
    display: flex;
}

.uoList{
    width:60%;
}

.uoList ul{
   padding: 1rem;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;

}

.uoList ul li{
    color: #fff;
    font-size: 1.2rem;
}

.head{
    margin-bottom: 1rem;
}

.head h2{
    font-weight: 500;
    line-height: 1.4;
    font-size: 1.7rem;
    color:#fff
}


.cardImageWrapper{
    width: 270px;
    height: 195px;
    position: absolute;
    right: -5px;
    bottom: 5px;
}

.cardImageWrapper > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media screen and (max-width:700px){
    .head h2{
        font-size: 1.3rem;
    }
    .uoList ul li {
        font-size: 0.9rem;
    }
    .cardImageWrapper{
        width: 200px;
        height: 130px;
    }
}

@media screen and (max-width:470px){
    .uoList{
        width: 100%;
    }
    .cardImageWrapper{
        display: none;
    }
}
 .aboutUsWrapper{
    padding-top: 4rem;
 }
 
 .aboutUsHeader{
    width: 80%;
    margin: auto;
 }

.aboutUsHeader h1{
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
}

 .aboutUsHeader p{
    padding: 2rem;
    font-size: 1.25rem;
    font-weight: 400;    
    line-height: 1.6;
    color: #677788;
    text-align: center;
 }

 .whatWeDo{
    margin: 4rem 0;
 }
.whatWeDo h1{
    text-align: center;
    font-size: 2.1rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
}
 .salesForceImp,.digitalMarketing{
    margin-top: 4rem;
    display: flex;
    width: 100%;
 }

 .leftPart{
    width: 30%;
 }

 .leftPart h2{
    font-weight: 500;
    line-height: 1.5;
    color: #1e2022;
 }

 .rightPart{
    width: 70%;
 }

 .rightPart p{
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: left;
 }
 
 @media screen and (max-width:900px){
   .aboutUsHeader{
      width: 100%;
   }

   .digitalMarketing,.salesForceImp{
      flex-direction: column;
      gap: 1.5rem;
   }

   .leftPart,.rightPart{
      width: 100%;
   }

   .digitalMarketing{
      margin-top: 2rem;
   }
 }

 @media screen and (max-width:700px){
   .aboutUsHeader p{
      font-size: 1rem;
      padding: 2rem 0;
   }

   .rightPart p{
      font-size: 1rem;
   }
   .leftPart h2{
      font-size: 1.2rem;
   }
 }
.footContainer{
    background-color: #21325b;
    padding: 4rem 8rem;
    margin-top: 5rem;

}

.footWrapper{
    display: flex;
    gap:2rem
}

.address .footImg{
    width: 120px;
    height: 22px;
    margin-bottom: 1.3rem;
    cursor: pointer;
}

.footImg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.address{
    flex: 1 1;
}
.address ul{
    display: flex;
    flex-direction: column;
    gap:1.3rem
}
.address ul li{
    display: flex;
    gap: 0.5rem;
    list-style-type: none;
    color: rgba(255,255,255,.7);
    font-size: .875rem;
    font-weight: 500;
}

.address ul li span{
    cursor: pointer;
}

.address ul li span:hover{
    color: #fff;
}

.rightSec{
    flex: 1 1;
    display: flex;

}
.company{
    flex: 1 1;
}
.cont{
    color: #fff;
    margin-bottom: 1.3rem;
}

.company ul{
    display: flex;
    flex-direction: column;
    gap:1.3rem
}

.company ul li{
    list-style-type: none;
}
.company ul li a{
    text-decoration: none;
    font-size: .875rem;
    color: rgba(255,255,255,.7);
    font-weight: 500;
}
.company ul li a:hover{
    color: #fff;
}

.hiring{
    display: inline-block;
    padding: 0.275rem 0.4em;
    font-size: 75%;
    margin-left: 0.4rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 7px;
    transition: all .2s ease-in-out;
    background-color: #377dff;
    color: #fff;
}

.resources{
    flex: 1 1;
}

.resources ul li{
    list-style-type: none;
}

.resources ul li a{
    display: flex;
    gap: 0.5rem;
    text-decoration: none;
    font-size: .875rem;
    color: rgba(255,255,255,.7);
    font-weight: 500;
}

.resources ul li a:hover{
    color: #fff;
}

.lineBreak{
    height: 0.0625rem;
    opacity: .2;
    margin-top: 4rem;
}

.copyRightSection{
    font-size: 80%;
    font-weight: 400;
    opacity: .4;
    color:#fff;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;
}

.copyRightSection p:first-child{
    margin-top: 2rem;
    margin-bottom: 1rem;

}


@media screen and (max-width:990px){
    .footContainer{
        padding: 4rem 3rem;
    }
}

@media screen and (max-width:760px){
   .footWrapper{
    flex-direction: column;
   }
   .copyRightSection{
    align-items: flex-start;
}
}

@media screen and (max-width:530px){
    .footContainer{
        padding: 4rem 1rem;
    }
}
.salesForceWrapper{
    display: flex;
    gap: 1.5rem;
}

.imgWrapper{
    width: 56px;
    height: 56px;
}

.imgWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.right_sec{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}
.right_sec h2{
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.2rem;
}
.right_sec p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: left;
}

@media screen and (max-width:430px){
 .right_sec h2{
    font-size: 0.9rem;
 }  
 .right_sec p{
    font-size: 0.8rem;
 } 
}
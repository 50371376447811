.serviceHeading{
    margin-top: 3rem;
}

.serviceHeading h1{
    margin: 3rem 0;
    padding: 3rem 13rem;
    font-size: calc(1.26609rem + .19312vw);
    color: #1e2022;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    font-size: 2.5rem;
}

.serviceHeading h1 span{
    color:#00A2E1;
}

.heroPart{
    display: flex;
    gap:2rem;
}

.leftHeroImg{
    display: flex;
    align-items: flex-end;
    flex:1 1;
}

.rightHeroImg{
    flex:1 1;
}

.rightImg{
    width: 100%;
    height: 70vh;
}
.leftImg{
    width: 100%;
    height: 55vh;
}
.leftImg img,.rightImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
}

.professionalService{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.professionalService span{
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: center;
    text-transform: uppercase;
}

.professionalService h2{
    font-weight: 600;
    line-height: 1.5;
    font-size: 2rem;
    color: #677788;
    text-align: center;
    padding: 0 10rem;
}

.professionalServiceContent{
    margin: 6rem 0;
    padding: 0 10rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px,1fr));
    justify-content: space-around;
    grid-gap:3rem

}

.cardAndImagePart{
    display: flex;
    gap:2rem;
    justify-content: space-between;
}

.marketingCloud{
    margin-top: 5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}
.marketingCloud a{
    display: flex;
    justify-content: center;
}

@media screen and (max-width:1150px){
    .serviceHeading h1{
        padding: 3rem 3rem;
    }
    .professionalService h2{
        padding: 1rem 3rem;
    }

    .professionalServiceContent{
        padding: 0 1rem;
    }

    .cardAndImagePart{
        flex-direction: column;
    }
}

@media screen and (max-width:900px){
    .professionalServiceContent{
        grid-template-columns: repeat(1, minmax(270px,1fr));

    }
}

@media screen and (max-width:800px){
    .heroPart{
        flex-direction: column;
    }
    .leftHeroImg,.rightHeroImg{
        justify-content: center;
    }

    .rightHeroImg{
        display: flex;
        justify-content: center;
    }
    .leftImg{
        width: 70vw;
        height : 40vh;
    }
    .rightImg{
        width: 70vw;
        height: 40vh;
    }
    .professionalService h2{
        font-size: 1rem;
    }
    .serviceHeading h1{
        font-size: 1.4rem;
    }
}

@media screen and (max-width:500px){
    .professionalService h2{
        padding: 1rem 0;
    }

    .serviceHeading h1{
        padding: 3rem 0;
    }
}


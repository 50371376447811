.circleCardContainer{
    height: inherit;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap:1.5rem
}

.circleCardImg{
    display: flex;
    justify-content: center;
}

.CircleCardImgContainer{
    box-shadow:0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-radius: 50%;
    width: fit-content;
    padding: 2.5rem;
    height: 250px;
    display: flex;
     align-items: center;
}

.CircleCardImgWrapper{
    width: 170px ;
    height: 48px;
}

.CircleCardImgWrapper > img{
    width: 100%;
     height:100%;
    object-fit:cover
}

.brandName > h4{
    font-weight: bold;
}

.brandDesc{
    display: flex;
    justify-content: center;
    align-items: center;
}

.brandDescWrapper{
    box-shadow:0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 0.5rem 2rem;
}

.brandDescWrapper >h4{
    margin: 0; 
    padding:0;
    color:#1190d8
}
.card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #eaf5fe;
    border-radius: 10px;
    height: 450px;
    gap:2.5rem;
    padding: 4rem;
}
.cardContainer{
  
}

.cardImg{

}

.cardHeading{
    font-size: 1.5rem;
    color: #032d60;
    text-align: center;
}

.description{
    text-align: center;
    color: #677788;
}
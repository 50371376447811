.careerContainer{
    margin-top: 6rem;
}

.btn,.workWithUs a{
    background-color: #377dff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #f7faff;    
    font-weight: bold;
    line-height: 1.6;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.workWithUs a{
    text-decoration: none;
}
.btn:hover,.workWithUs a:hover{
    color: #677788;
    box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
    transform: translateY(-2px);
}

.workWithUs{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
}

.workWithUs h1{
    text-align: center;
    font-weight: 500;
    font-size: 2.9rem;
}

.workWithUs p{
    padding: 0 12rem;
    font-size: 1.1rem;
    text-align: center;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
}

.gallery{
    background-image: url("/public/images/abstract-shapes-12.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 -8rem;
    margin-top: 3rem;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap:1rem
}

.galleryTop {
    display: flex;
    gap:1rem;
    justify-content: flex-end;
    align-items: flex-end;
}

.galleryBottom{
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
}

.galleryTop div:nth-child(1),.galleryBottom div:nth-child(2){
    height: 65%;
    width: 27%;
}

.galleryTop div:nth-child(2),.galleryBottom div:nth-child(1){
    width: 63%;
    height: 100%;
}


.galleryTop img, .galleryBottom img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.galleryBottom {
    display: flex;
}

.companyValues{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.header{
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.header h1,.header p{
    text-align: center;
}
.header p{
    font-size: 0.9rem;
}
.header h1{
    font-size: 2rem;
    padding: 1rem 13rem;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}

.cont{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:2rem;
    row-gap: 4rem;
}

.contBox{
    display: flex;
    
}

.box{
    display: flex;
    flex-direction: column;
    gap:1rem
}

.box h2{
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4;
    color: #1e2022;
}

.box p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: left;
}

.contBox span{
    margin-right: 1rem;
}

.openPosition{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.knowMore{
    flex-wrap: wrap;
    display: flex;
    padding: 1rem 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 0.75rem;
    border: 0.0625rem solid rgba(231,234,243,.7);
    box-shadow: none;
    transition: .3s;
}

.knowMore:hover{
    border: 0.0625rem solid #0052ea;
}

.knowMore div:nth-child(1){
    flex: 0 0 50%;
}

.knowMore div:nth-child(2){
    flex: 0 0 25%;
}
.knowMore div:nth-child(3){
    flex: 0 0 25%;
}

.knowMore div:nth-child(3) p{
    color: #1e2022;    
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}

.knowMore div:nth-child(3) p{
    text-align: end;
}

.knowMore div:nth-child(3) p a{
    color:#0052ea;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    text-decoration: none;
}
.looking{
    margin-top: 2rem;
}
.apply{
    text-align: center;
    margin-top: 2rem;
}
.looking p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: center;
}


@media screen and (max-width:1115px){
    .workWithUs p{
        padding: 1rem;
    }
    .header h1{
        padding: 1rem;
    }
}

@media screen and (max-width:990px){
    .gallery{
        margin: 0 -3rem;
    }
}
@media screen and (max-width:910px){
    .workWithUs h1{
        font-size: 1.9rem;
    }
    .cont{
        grid-template-columns: repeat(1,1fr);
    }
}

@media screen and (max-width:680px){
    .knowMore{
        flex-direction: column;
        align-items: flex-start;
        gap:0.5rem;
    }
    .header h1{
        padding: 1rem 0;
        font-size: 1.5rem;
    }
}
@media screen and (max-width:530px){
    .gallery{
        margin: 0 -1rem;
    }

    .gallery{
        padding: 2rem 1rem;
    }
}
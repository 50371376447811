
nav{
    width: 100%;
    top:0;
    padding: 0 8rem;
    position: fixed;
    height: 73.59px;
    background-color: #fff;
    z-index: 999;
}

.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 73.59px;
}
.logo{
    width: 120px;
    height: 22px;
    cursor: pointer;
}

.logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navContent{
    flex: 1 1;
    display: flex;
    justify-content: flex-end;

}
.navContent ul{
    gap:1rem;
    display: flex;
}
.navContent ul li{
    list-style: none;
    line-height: 73.59px;
    padding: 0 1rem;
    cursor: pointer;
}

.navContent ul li:last-child{
    padding-right: 0;
}

.navContent ul li button,.content ul li button{
    background-color: transparent;
    border:none;
    font-size: 1rem;
    cursor: pointer;
}

.navContent ul li button.dMarketing,.content ul li button.dMarketing{
    background-color: #377dff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #f7faff;    
    font-weight: bold;
    line-height: 1.6;
    transition: all 0.5s ease-in-out;
}

.navContent ul li button.dMarketing:hover,.content ul li button.dMarketing:hover{
    box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
    transform: translateY(-2px);
    color: #677788;
}
.explore{
    position: relative;
}

.explore::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    width: 1rem;
    height: 1rem;
    right: -28px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    content: '';
}
.navContent ul li:hover > .explore,.content ul li:hover > .explore{
    color: #377dff;
}
.navContent ul li:hover > .megaContent{
    /* visibility: visible; */
    display: block;
    /* opacity: 1; */
    animation: fade 300ms ease-in-out;
    animation-fill-mode: forwards; 
    /* transform: translateY(-5.5px); */
}

.upSubContent{
    margin-top: 0.7rem;
    padding: 1rem;
    background-color: #fff;
    height: calc(290px - 0.7rem);
    border: 2px solid transparent;
    border-top: 3.5px solid #377dff;
    box-shadow: 1px 2px 1px 1px #bbbbbb5a;
    border-radius: 5px;
}

.megaContent{
    margin: 0 8rem;
    /* opacity: 0; */
    display: none;
    /* visibility: hidden; */
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
    min-height:200px ;
    border: 0 solid rgba(0,0,0,.15);
    height:290px ;
    border-radius: 5px;
    background-color: transparent;
    /* transition: all 0.20s ease-in-out; */
}


@keyframes fade {
    0% {
        /* opacity: 0; */
        transform: translateY(0);

    }
    100% {
        /* opacity: 1; */
        transform: translateY(-5px);
    }
}


.megaContent:hover{
    opacity: 1;
    /* visibility: visible; */
    display: block;
    cursor: default;
}

.subNavContent{
    line-height: normal;
    display: flex;
    height: 100%
}
.leftSection{
    width: 42%;
    background-image: url("../../public/images/shape-3.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius:0.5rem ;
    gap:1rem
}
.cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    gap: 1.3rem;
}
.cont p{
    text-align: center;
    color: #677788;
}
.name{
    font-weight: 500;
    line-height: 1.6;
    font-size: 2rem;
    letter-spacing: 1.3px;
}

.rightSection{
    flex: 1 1;
    margin-left: 3rem;
    display: flex;
    padding-top: 1rem;
    /* border: 3.5px solid #1596ca; */
}
.classic{
    flex: 1 1;
}

.classic > span, .letsConnect > span{
    display: block;
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    font-size: .75rem;
    color: #8c98a4;
    letter-spacing: .03125rem;
     white-space: nowrap;
     margin-bottom: .35rem;
}

.classic ul li a, .letsConnect ul li a{
    color: black;
    font-weight: bolder;
    text-decoration: none;
    padding: 0.375rem 1.5rem;
    letter-spacing: .03125rem;
    font-size: .875rem;
}

.classic ul li, .letsConnect ul li{
    padding: 0;
}

.letsConnect ul li a > span{
        display: inline-block;
        padding: 0.275rem 0.5em;
        font-size: 75%;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 1.3125rem;
        transition: all .2s ease-in-out;
        background-color: #377dff;
        color: #fff;
}

.classic ul li a:hover,.letsConnect ul li a:hover{
    color: #377dff;
}
.classic ul, .letsConnect ul{
    gap: 1.4rem;
}
.letsConnect{
    flex: 1 1;
}

.navLink{
    flex-direction: column;
}

.navLink li{
    line-height: normal !important;
}

.enhanceNavBar{
    box-shadow: 0 0.575rem 1.5rem 0 rgb(140 152 164 / 13%);
  }

  .responsive{
    display: block;
  }

  .mobileNav{
    display: none;
  }

  .bar{
    cursor: pointer;
  }


  .content{
    height: 0;
    opacity: 0;
    overflow: hidden;
    animation: mobile 500ms ease-in-out;
    animation-fill-mode: forwards; 
  }

  .content ul{
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 1rem;
  }

  .content ul li {
    list-style-type: none;
  }

  .visible{
    height: 7.5rem;
    background-color: #fff;
  }

.fullHeight{
    height : 21rem !important
}

@media screen and (min-width:1400px){
    nav{
        width: 1400px;
        left: 50%;
        transform: translate(-50%);
    }
}

@media screen and (max-width:1400px){
    nav{
        width: 100%;
        left: 0;
        transform: translate(0);
    }
}

  @media screen and (max-width:990px){
    nav{
        padding: 0 3rem;
    }
    .megaContent{
        margin: 0 3rem;
    }
  }

  @media screen and (max-width:840px){

      .responsive{
        display: none;
      }
      .mobileNav{
        display: block;
      }

      @keyframes mobile {
        0% {
            opacity: 0;
            height: 0;
        }
        10%{
            opacity: 0.1;
            height: 10%;
        }
        20%{
            opacity: 0.2;
            height: 20%;
        }
        30%{
            opacity: 0.3;
            height: 30%;   
        }
        40%{
            opacity: 0.4;
            height: 40%;
        }
        50%{
            opacity: 0.5;
            height: 50%;   
        }
        60%{
            opacity: 0.6;
            height: 60%;  
        }
        70%{
            opacity: 0.7;
            height: 70%;
        }
        80%{
            opacity: 0.8;
            height: 80%;   
        }
        90%{
            opacity: 0.9;
            height: 90%;
        }
        100% {
            opacity: 1;
            height: 100%;
        }
    }

      .visible{
          display: block !important;
      }

      .subVisible{
        display: block !important;
        border-left: 1px solid #377dff;
      }

      .subVisible .rightSection .classic{
          border-left: 1px solid #377dff;
      }

      .subVisible .rightSection{
        margin-left: 1rem !important;
        margin-left: 0;
        opacity: 0;
        overflow: hidden;
        animation: mobile 500ms ease-in-out;
        animation-fill-mode: forwards; 
      }

      .content ul li a{
        padding: 0 1rem;
      }
      .content span{
        padding: 0 2rem;
        padding-top: 1rem;
      }

      .content .letsConnect ul{
        margin-bottom: 0 !important;
      }
      .content .letsConnect{
        padding-top: 1rem;
      }
      .content .letsConnect > span{
        padding-top: 0;
      }
  }

  @media screen and (max-width:530px){
    .content .rightSection{
        flex-direction: column;
    }
    nav{
        padding: 0 1rem;
    }
    .subVisible .rightSection .letsConnect{
        border-left: 1px solid #377dff;
    }
    .flow{
        overflow-y: auto;
    }
  }

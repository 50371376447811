.container{
    min-height: calc(100vh - 73.59px);
    margin-top: 73.59px;
    padding: 0 8rem;
    width:100%;
    border: 1px solid #fff;
}
@media screen and (min-width:1400px){
    .container{
        width: 1400px;
        margin: auto;
    }
}

@media screen and (max-width:1400px){
    .container{
        width: 100%;
    }
}

@media screen and (max-width:990px){
    .container{
        padding: 0 3rem;
    }
}

@media screen and (max-width:530px){
    .container{
        padding: 0 1rem;
    }
}
.contactUsContainer{
    padding:6rem 0 ;
    display: flex;
    gap: 2rem;
}

.parentAddress{
    display: flex;
    flex-direction: column;
    gap:2rem;
    flex: 1 1;
}

.parentAddress .address,.parentAddress .otherAddress{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.208);
}

.parentAddress .address .left,.parentAddress .otherAddress .left{
    display: flex;
}

.parentAddress .otherAddress .detail{
    display: flex;
    flex-direction: column;
    gap:0.7rem
}

.parentAddress .head{
    display: flex;
    flex-direction: column;
    gap:0.7rem
}

.parentAddress .head h1{
    font-weight: 600;
}

.parentAddress .head p{
    font-weight: 600;
    color: #677788;
}

.parentAddress .address .left >div,.parentAddress .otherAddress .left >div{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap:0.7rem
}

.parentAddress .address .right > div,.parentAddress .otherAddress .right > div{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap:0.7rem
}

.parentAddress .address .left h3,.parentAddress .otherAddress .left h3{
    font-weight: 600;
    font-size: 95%;
}

.parentAddress .address .left p,.parentAddress .otherAddress .left p{
    font-size: 80%;
    color: #677788;
}

.parentAddress .address .right p,.parentAddress .otherAddress .right p{
    font-size: 80%;
    color: #677788;
}

.parentAddress .address .right h3,.parentAddress .otherAddress .right h3{
    font-weight: 600;
    font-size: 95%;
}

.contactForm{
    flex: 1 1;
}

.formPart{
    width: 100%;
    z-index: -5;
    background-image: url("../../../public/images/shape-3.svg");
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:2rem 1rem;
    box-shadow: 1px 1px 1px 1px #4e5862;
    justify-content: center;
    border-radius: 0.75rem;
    box-shadow: 0 10px 40px 30px rgba(140,152,164,.175)
}

.formPartWrapper{
    width: 95%;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.workingTogetherBtn .btn{
    background-color: #377dff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #f7faff;    
    font-weight: bold;
    min-width: 8rem;
    line-height: 1.6;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.workingTogetherBtn .btn:hover{
    color: #677788;
    box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
    transform: translateY(-2px);
}

.formPartWrapper input,.formPartWrapper textarea{
    width: 100%;
    border: 1px solid #377dff;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: #677788;
    /* background-color: #377dff1c; */
}
.inputField{
    position: relative;
    z-index: 1;
}
.formPartWrapper label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #377dff;
    transition: all 250ms ease-in-out;

}
input:focus ~ label,
input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
textarea:focus ~ label,
textarea:valid ~ label{
    transform: translateY(-35px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

textarea{
    resize: none;
}

.workingTogetherBtn{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:1rem
}

.workingTogetherBtn p{
    font-size: 80%;
    font-weight: 400;
    opacity: .4;
    color:#4e5862;
}

.disable{
    cursor: not-allowed !important;
}

.messageResponse p{
    text-align: center;
    padding: 0.5rem;
    color:#377dff;
    font-weight: bold;
}

@media screen and (max-width:890px){
    .contactUsContainer{
        flex-direction: column;
        gap:5rem;
        padding: 3rem 0;
    }
}
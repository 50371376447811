.detailContainer{
    display: flex;
    margin-top: 3rem;
}

.right{
    flex: 0 0 40%;
}

.left{
    flex: 0 0 60%;
    padding-right: 3rem;
}

.formPart{
    width: 100%;
    z-index: -5;
    background-image: url("../../../public/images/shape-3.svg");
    margin-top: 0;
    display: flex;
    padding: 1rem;
    box-shadow: 1px 1px 1px 1px #4e5862;
    justify-content: center;
    border-radius: 0.75rem;
    box-shadow: 0 10px 40px 30px rgba(140,152,164,.175)
}

.formPartWrapper{
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.workingTogetherBtn{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:1rem
}

.workingTogetherBtn p{
    font-size: 80%;
    font-weight: 400;
    opacity: .4;
    color:#4e5862;
}

.workingTogetherBtn .btn{
    background-color: #377dff;
    border: 0.0625rem solid transparent;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #f7faff;    
    font-weight: bold;
    line-height: 1.6;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.formPartWrapper input,.formPartWrapper textarea{
    width: 100%;
    border: 1px solid #377dff;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: #677788;
    /* background-color: #377dff1c; */
}

.inputField{
    position: relative;
    z-index: 1;
}
.formPartWrapper label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: #377dff;
    transition: all 250ms ease-in-out;

}
input:focus ~ label,
input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
textarea:focus ~ label,
textarea:valid ~ label{
    transform: translateY(-35px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

textarea{
    resize: none;
}

.file{
    position: relative;
}

.file input[type="file"]{
    padding: 1rem;
}

.file div{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.file label{
    transform: translateY(0);
    left: 0;
    position: relative;
}

.workingTogetherBtn .btn:hover{
    color: #677788;
    box-shadow: 0 12px 15px rgb(140 152 164 / 10%);
    transform: translateY(-2px);
}

.fullTime p{
    letter-spacing: .03125rem;
    font-size: 80%;
    line-height: 1.6;
    color: #677788;
}

.name{
    margin-top: 1rem;
}

.name h1{
    font-weight: 500;
    line-height: 1.4;
    color: #1e2022;
}

.desc{
    margin-top: 2rem;
}

.desc p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
    text-align: left;
}

.responsibility,.requirements,.experience,.covid{
    margin-top: 1rem;
}

.responsibility h4,.requirements h4,.experience h4,.covid h4{
    font-weight: 500;
    line-height: 1.4;
    color: #1e2022;
    font-size: 1.1rem;
}

.responsibility ul,.requirements ul,.experience ul,.covid ul{
    padding-left: 2rem;
    padding-top: 0.7rem;
}

.responsibility ul li,.requirements ul li,.experience ul li,.covid ul li{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #677788;
}

.disable{
    cursor: not-allowed !important;
}

.messageResponse p{
    text-align: center;
    padding: 0.5rem;
    color:#377dff;
    font-weight: bold;
}

@media screen and (max-width:1050px){
    .detailContainer{
        flex-direction: column;
        gap:4rem
    }

    .left{
        padding-right: 0;
    }
}

@media screen and (max-width:450px){
    .name h1{
        font-size: 1.5rem;
    }
}
